$(document).ready(function () {
    // detect navbar position
    var domain = 'https://www.haciendarealfoco.com/';
    var home_ = domain;
    var about_ = domain + 'about';
    var menu_ = domain + 'menu';
    var specials_ = domain + 'specials';
    var catering_ = domain + 'catering';
    var parties_ = domain + 'parties';
    var jobs_ = domain + 'jobs';
    var accessibility_ = domain + 'accessibility';
    const pages = [
        { page: 'home', file: home_ },
        { page: 'about', file: about_ },
        { page: 'menu', file: menu_ },
        { page: 'specials', file: specials_ },
        { page: 'catering', file: catering_ },
        { page: 'parties', file: parties_ },
        { page: 'jobs', file: jobs_ },
        { page: 'accessibility', file: accessibility_ }
    ];
    $('.home, .about, .menu, .specials, .catering, .parties, .jobs, .accessibility').each(function () {
        $(this).click(function () {
            var clickedClass = $(this).attr('class').split(' ')[0];

            var pageObj = pages.find(function (page) {
                return page.page === clickedClass;
            });

            if (pageObj) {
                var file = pageObj.file;

                window.location.href = file;
                //console.log('Haz clic en ' + file);
            }
        });
    });
    //add nav activo
    function agregarClaseNavActivo() {
        var currentPath = window.location.pathname;

        $('.page').each(function () {
            var link = $(this).text().toLowerCase();

            if (currentPath.includes(link)) {
                $(this).addClass('nav_activo');
            }
        });

        if (currentPath.endsWith("/")) {
            $('.home').addClass('nav_activo');
        }
    }
    $(document).ready(function () {
        agregarClaseNavActivo();
    });
    //delete photo link to url on gallery
    var allowedPaths = ['/home', '/about', '/'];
    var currentPath = window.location.pathname.replace('.html', '');
    //console.log('Ubicación: ' + currentPath);
    if (allowedPaths.includes(currentPath)) {
        $('[data-fancybox="gallery"], [data-fancybox="gallery_2"]').fancybox({
            hash: false
        });
    }
    //add classes on load
    $('header section img').addClass('transition');
    $('body main article.content section:nth-child(1) div').addClass('fade-from-left');
    $('body main article.content section:nth-child(2) img').addClass('fade-from-right');
    $('body main article.info_btn span.address_link i').addClass('bounce-in');
    //add property visibility visible to body
    $('body').css('visibility', 'visible');
    // function on scroll
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 30) {
            $('header').addClass('sticky_header');
        } else {
            $('header').removeClass('sticky_header');
        }
    });
    // add class on scroll to button up
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 200) {
            $('.up').addClass('show_btn');
        } else {
            $('.up').removeClass('show_btn');
        }
    });
    // click off
    $(document).on('click', function (event) {
        var button = $('.order');
        var dropdown = $('.orders');
        if (!dropdown.is(event.target) && !button.is(event.target)) {
            dropdown.css('display', 'none');
            dropdown.removeClass('despliega');
            $(button).removeClass('rotate_reverse');
        }
    });
    // dropdown menu
    $('.order').on('click', function () {
        var dropdown = $('.orders');
        if (dropdown.css('display') == 'none' || dropdown.css('display') == '') {
            dropdown.css('display', 'flex');
            dropdown.addClass('despliega');
            $(this).addClass('rotate_reverse');
        } else {
            dropdown.css('display', 'none');
            dropdown.removeClass('despliega');
            $(this).removeClass('rotate_reverse');
        }
    });
    // button up
    var upUp = $(".up");
    upUp.on("click", function () {
        $(window).scrollTop(0);
        //console.log("Vamos arriba");
    });
    // Establecer el año
    var yir = new Date().getFullYear();
    var yy = new Date();
    $('.year').text(yir);
    console.log(yy);
    // navbar on menu
    function menuTabs() {
        var tabs = $('.menu_tabs');
        var menuDivs = $('.mm');

        menuDivs.hide();

        menuDivs.eq(0).show();
        tabs.eq(0).addClass('colorea');

        tabs.each(function (index) {
            $(this).click(function () {
                menuDivs.hide();

                menuDivs.eq(index).show();

                tabs.removeClass('colorea');
                $(this).addClass('colorea');

                //console.log('Pestaña clickeada: ' + $(this).text());
                //console.log('Div mostrado: ' + menuDivs.eq(index).attr('class'));
            });
        });
    }
    menuTabs();
    //add link to logo
    $('.logo').click(function () {
        window.location.href = domain;
    });
    //get li from navigation navbar on mobile
    $('li.silence button').on('click', function () {
        console.log('Vamos bien');
        $(this).toggleClass('clickeado');
        //$('header nav ul').toggleClass('expandete');
        $('.nav_mobile_mob').toggleClass('expandete');
    });
    //delete html to url
    function removeHtmlExtension(url) {
        if (url.endsWith('.html')) {
            return url.slice(0, -5);
        }
        return url;
    }
    //scroll-to
    $(document).ready(function () {
        // Capturar el evento clic en los enlaces internos
        $('span[data-scroll-to]').click(function (event) {
            event.preventDefault();

            var target = $(this).data('scroll-to');
            var offset = $(target).offset().top;

            $('html, body').animate({ scrollTop: offset }, 500);
        });
    });
    // run swiper on home and about
    var allowedPaths = ['/home', '/about', '/'];
    var currentPath = window.location.pathname.replace('.html', '');
    //console.log('Ubicación: ' + currentPath);
    if (allowedPaths.includes(currentPath)) {
        $(document).ready(function () {
            var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

            var cycle = function (index) {
                var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

                nextIndex = index !== undefined ? index : nextIndex;

                $currentSlide = $($slides.get(currentIndex));
                $currentSelector = $($selectors.get(currentIndex));

                $nextSlide = $($slides.get(nextIndex));
                $nextSelector = $($selectors.get(nextIndex));

                $currentSlide.removeClass("active").css("z-index", "0");

                $nextSlide.addClass("active").css("z-index", "1");

                $currentSelector.removeClass("current");
                $nextSelector.addClass("current");

                currentIndex = index !== undefined
                    ? nextIndex
                    : currentIndex < $slides.length - 1
                        ? currentIndex + 1
                        : 0;

                nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
            };

            $(function () {
                currentIndex = 0;
                nextIndex = 1;

                $slides = $(".slide");
                $selectors = $(".selector");
                $btns = $(".btn");

                $slides.first().addClass("active");
                $selectors.first().addClass("current");

                interval = window.setInterval(cycle, 4000);

                $selectors.on("click", function (e) {
                    var target = $selectors.index(e.target);
                    if (target !== currentIndex) {
                        window.clearInterval(interval);
                        cycle(target);
                        interval = window.setInterval(cycle, 4000);
                    }
                });

                $btns.on("click", function (e) {
                    window.clearInterval(interval);
                    if ($(e.target).hasClass("prev")) {
                        var target = currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
                        cycle(target);
                    } else if ($(e.target).hasClass("next")) {
                        cycle();
                    }
                    interval = window.setInterval(cycle, 4000);
                });
            });

            var menu = [];
            $(".swiper-slide").each(function (index) {
                menu.push($(this).find(".slide-inner").attr("data-text"));
            });

            var interleaveOffset = 0.5;
            var swiperOptions = {
                loop: true,
                speed: 1000,
                parallax: true,
                autoplay: {
                    delay: 40000000,
                    disableOnInteraction: false
                },
                loopAdditionalSlides: 0,
                watchSlidesProgress: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                on: {
                    progress: function () {
                        var swiper = this;
                        for (var i = 0; i < swiper.slides.length; i++) {
                            var slideProgress = swiper.slides[i].progress;
                            var innerOffset = swiper.width * interleaveOffset;
                            var innerTranslate = slideProgress * innerOffset;
                            swiper.slides[i].querySelector(".slide-inner").style.transform = "translate3d(" + innerTranslate + "px, 0, 0)";
                        }
                    },
                    touchStart: function () {
                        var swiper = this;
                        for (var i = 0; i < swiper.slides.length; i++) {
                            swiper.slides[i].style.transition = "";
                        }
                    },
                    setTransition: function (speed) {
                        var swiper = this;
                        for (var i = 0; i < swiper.slides.length; i++) {
                            swiper.slides[i].style.transition = speed + "ms";
                            swiper.slides[i].querySelector(".slide-inner").style.transition = speed + "ms";
                        }
                    }
                }
            };

            var swiper = new Swiper(".swiper-container", swiperOptions);

            var sliderBgSetting = $(".slide-bg-image");
            sliderBgSetting.each(function (indx) {
                if ($(this).attr("data-background")) {
                    $(this).css("background-image", "url(" + $(this).data("background") + ")");
                }
            });
        });

    }
    // run swiper on home and about
    var allowedPaths = ['/home', '/'];
    var currentPath = window.location.pathname.replace('.html', '');
    //console.log('Ubicación: ' + currentPath);
    if (allowedPaths.includes(currentPath)) {
        //add property visibility visible to body
        $('body').addClass('no-scroll');
        $('.modal').addClass('show_modal');
        //close modal
        $('.btn_modal').on('click', function () {
            //$('.modal').removeClass('show_modal');
            $('.modal').addClass('animationless');
            $('.wrap').addClass('animationless2');
            $('body').removeClass('no-scroll');
        });
    }
    //theme color
    var themeColor = getComputedStyle(document.documentElement).getPropertyValue('--primary').trim();
    // Actualiza el atributo "content" de la etiqueta meta
    $('meta[name="theme-color"]').attr('content', themeColor);
    // get vh
    const alturaViewport = $(window).height();
    const vhEquivalentes = (600 / alturaViewport) * 100;
    console.log('the view height is ' + vhEquivalentes);
    //--------------------------------------- Data//---------------------------------------
    //Location Info
    LOCATION_NAME = 'hacienda real';
    ADDRESS = '421 Centro Way, Fort Collins, CO 80524';
    ADDRESS_URL = 'https://goo.gl/maps/7RWCm8WyZiLfzx8n7';
    PHONE_NUMBER = '9702212535';
    PHONE_TEXT = '(970) 221-2535';
    HOURS_ST = '11:00 a.m. - 9:00 p.m.';
    HOURS_ND = '11:00 a.m. - 10:00 p.m.';
    D1 = 'sun - thu';
    D2 = 'fri - sat';
    //Social media
    FACEBOOK = 'https://www.facebook.com/HaciendaRealMexicanRestaurant/';
    INSTAGRAM = 'https://www.instagram.com/haciendarealmexicanrestaurant/';
    YELP = 'https://www.yelp.com/biz/hacienda-real-fort-collins?osq=hacienda+real';
    //3Rd Party Vendors
    PICK_UP = 'https://www.haciendarealtogo.com/ordering/restaurant/menu?restaurant_uid=8ef0adc2-c835-4981-80f7-d69c3409fa0b&client_is_mobile=true&glfa_cid=604826432.1592875289&glfa_t=1592875290192';
    DELIVERY1 = 'https://www.grubhub.com/restaurant/hacienda-real-421-centro-way-fort-collins/1125722?utm_source=google&utm_medium=organic&utm_campaign=place-action-link';
    //Google Reviews
    GOOGLE = 'https://www.google.com/search?channel=fs&client=ubuntu-sn&q=hacienda+real+fort+collins#lrd=0x876eb518b128531b:0xc79b362024afae01,3,,,,';
    GOOGLE_MOB = 'https://www.google.com/search?q=hacienda+real+fort+collins+reviews&oq=hacienda+real+fort+collins&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDY2OTdqMGo0qAIAsAIA&client=ms-android-attmexico-mx-revc&sourceid=chrome-mobile&ie=UTF-8&si=ACFMAn9dOWFXLVp-5_qz1cA5iL8vSthzhlxpXWfPEY0sZP2iVVmd6XOp_zy_idhtqakolYBwze9kcUu8YIwUOLLlkVSEKyWKEt7t0e8TCFrdCXvOnTMSb2LzJ5jCCILH8WVm9iNFWJ0W3mb6zWm321TzrWq6LQUqoFp0PtdfFmlE8prw2FXH5Y4%3D&ictx=1&ved=2ahUKEwigwcifk_GAAxWKKEQIHZ4_CrMQyNoBKAJ6BAgrEAk';
    //About Us
    ABOUT_US = "Local, family-owned and operated, Hacienda Real is a Mexican restaurant offering delicious Mexican cuisine.  <br><br> In business for 15 years, our staff is dedicated to providing you with delicious food and quality service. Visit us today for a delicious Mexican meal. Se Habla Español.";
    ABOUT_US_SHORTY = 'Local, family-owned and operated, Hacienda Real is a Mexican restaurant offering delicious Mexican cuisine. <br><br> In business for 15 years, our staff is dedicated to providing you with delicious...';
    //Iframe
    IFRAME = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3030.179727122442!2d-105.008055!3d40.5817881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876eb518b128531b%3A0xc79b362024afae01!2sHacienda%20Real!5e0!3m2!1ses!2smx!4v1692737491839!5m2!1ses!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    $('.text_about_home').each(function () {
        $(this).html(ABOUT_US_SHORTY);
    });
    $('.text_about').each(function () {
        $(this).html(ABOUT_US);
    });
    //Iframe
    $('.iframe').each(function () {
        $(this).html(IFRAME);
    });
    //Location Name
    $('.location_name').each(function () {
        $(this).text(LOCATION_NAME);
    });
    //Location Info
    $('.address_link').each(function () {
        $(this).click(function () {
            window.open(ADDRESS_URL, '_blank');
        });
    });
    $('.address_text').each(function () {
        $(this).text(ADDRESS);
    });
    //Phone
    $('.phone').each(function () {
        $(this).click(function () {
            window.location.href = 'tel:' + PHONE_NUMBER;
        });
    });
    $('.phone_number').each(function () {
        $(this).text(PHONE_TEXT);
    });
    //Days
    $('.day1').each(function () {
        $(this).text(D1);
    });
    $('.day2').each(function () {
        $(this).text(D2);
    });
    //Google Reviews 
    $('.go_desk').each(function () {
        $(this).click(function () {
            window.open(GOOGLE, '_blank');
        });
    });
    $('.go_mob').each(function () {
        $(this).click(function () {
            window.open(GOOGLE_MOB, '_blank');
        });
    });
    //Hours
    $('.h1').each(function () {
        $(this).text(HOURS_ST);
    });
    $('.h2').each(function () {
        $(this).text(HOURS_ND);
    });
    //Social Media
    $('.fb').each(function () {
        $(this).click(function () {
            window.open(FACEBOOK, '_blank');
        });
    });
    $('.ig').each(function () {
        $(this).click(function () {
            window.open(INSTAGRAM, '_blank');
        });
    });
    $('.yp').each(function () {
        $(this).click(function () {
            window.open(YELP, '_blank');
        });
    });
    //3Rd Party Vendors
    $('.pickup').each(function () {
        $(this).click(function () {
            window.open(PICK_UP, '_blank');
        });
    });
    $('.deliver1').each(function () {
        $(this).click(function () {
            window.open(DELIVERY1, '_blank');
        });
    });
});
